import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Button} from 'react-bootstrap';
import axios from "axios";
import Header from '../components/Header';
import CompanyInfo from '../components/CompanyInfo';
import Covid19Info from '../components/Covid19Info';
import EmployeeInfo from '../components/EmployeeInfo';
import DocumentInfo from '../components/DocumentInfo';
import GrossReceipts from '../components/GrossReceipts';
import Form941 from '../components/Form941';
import Payroll from '../components/Payroll';
import PPPLoans from '../components/PPPLoans';
import PowerOfAttorney from '../components/PowerOfAttorney';
import AgreementsDisclosures from '../components/AgreementsDisclosures';
import Thankyou from '../components/Thankyou';
import UserService from '../services/UserService';
import ClientChat from '../components/ClientChat';
import { useNavigate } from "react-router-dom";

const serverConfig = require("../config/server.js");

const Signup = () => {

  const navigate = useNavigate();

  const [token, setToken] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [userPrivileged, setUserPrivileged] = useState(false);

  const [stepStatus, setStepStatus] = useState([]);

  const [finalSteps, setFinalSteps] = useState([]);

  const [entityName, setEntityName] = useState("login");
  const [step, setstep] = useState(1);
  const [substep, setsubstep] = useState(1);
  
  const steps = [
    {
      stepHeading: "Company Information",
      stepCase: "company_info"
    },
    {
      stepHeading: "Covid-19 Information",
      stepCase: "covid19_info"
    },
    {
      stepHeading: "Employee Information",
      stepCase: "employee_info"
    },
    {
      stepHeading: "Upload Documents",
      stepCase: "document_info",
      subSteps: [
        {
          subStepHeading: "ID",
          subStepCase: "document_info"
        },
        {
          subStepHeading: "Gross Receipts",
          subStepCase: "gross_receipts"
        },
        {
          subStepHeading: "Form 941",
          subStepCase: "form_941"
        },
        {
          subStepHeading: "Payroll",
          subStepCase: "payroll"
        },
        {
          subStepHeading: "PPP loans",
          subStepCase: "ppp_loans"
        }
      ]
    },
    {
      stepHeading: "Power of Attorney",
      stepCase: "power_of_attorney"
    },
    {
      stepHeading: "Agreements & Disclosures",
      stepCase: "agreements_disclosures"
    },
    {
      stepHeading: "Thank You",
      stepCase: "thank_you"
    },
  ];

  const nextStep = () => {
    setstep(step + 1);
    setEntityName(steps[step].stepCase);
  };
  
  const nextSubStep = () => {
    setsubstep(substep + 1);
    console.log('nextSubStep', substep);
    console.log('nextSubStep', steps[step-1].subSteps[substep].subStepCase);
    setEntityName(steps[step-1].subSteps[substep].subStepCase);
  };

  const prevStep = () => {
    setstep(step - 1);
    setEntityName(steps[step-2].stepCase);
  };

  const prevSubStep = () => {
    setsubstep(substep - 1);
    setEntityName(steps[step-1].subSteps[substep-2].subStepCase);
  };

  const prevStepSub = () => {
    setstep(step - 1);
    setsubstep(steps[step-2].subSteps.length);
    setEntityName(steps[step-2].subSteps[steps[step-2].subSteps.length - 1].subStepCase);
  };

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    //let jwt = "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJTQXEzRG5SSUwzekxTS1oxeVJuR0xwejJWSy1kajk5TmxSRUgwRENvZTJRIn0.eyJleHAiOjE2NjQxODc5NTQsImlhdCI6MTY2NDE4NzY1NCwiYXV0aF90aW1lIjoxNjY0MTg3NjQ5LCJqdGkiOiIyZDEzMmIxYS1hMTI0LTQwMjItYmZlZi0yOGMzYjA2YjBiZWUiLCJpc3MiOiJodHRwczovL21hbmF0b2tvLWN0YmMtZGV2My5hbWJlcm9vbi5jb20vYXV0aC9yZWFsbXMvYXBwcyIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiI1YjIyMTFhMy1mYWM3LTRmYjgtOWI5NC0zMGY1YjU0ODU3MzciLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJjb25zb2xlLXVpIiwibm9uY2UiOiJlYzhjZjcxYi03Y2ZhLTRhOWItODhjZC0wZWI5N2I3NGI2NGIiLCJzZXNzaW9uX3N0YXRlIjoiOGNkYmU3ZjUtNGEwYi00MGIyLTk4ZjctN2MzNGU4NzdmZWZhIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL21hbmF0b2tvLWN0YmMtZGV2Mi5hbWJlcm9vbi5jb20iXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iLCJkZWZhdWx0LXJvbGVzLWFwcHMiXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwic2lkIjoiOGNkYmU3ZjUtNGEwYi00MGIyLTk4ZjctN2MzNGU4NzdmZWZhIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiR2VyYXJkIEFudG9ueSIsInByZWZlcnJlZF91c2VybmFtZSI6ImdlcmFyZC5hbnRvbnlAYW1iZXJvb24uY29tIiwiZ2l2ZW5fbmFtZSI6IkdlcmFyZCIsImZhbWlseV9uYW1lIjoiQW50b255IiwiZW1haWwiOiJnZXJhcmQuYW50b255QGFtYmVyb29uLmNvbSJ9.DuVO2AX2CoZbykow6TyJBFnql6lkfKVCV_KmIQn4QXdQJCfbesfH0He-5sFqv9OcmDU6UQvMmaZlYmmbPpPb0i6HDdiEZQcHzoI7eujFjb9BJcx1PuDqGYh0YV_fLKJL2F4krVFZT2M6glxqxf39G3ChnXBKKCMxBD2UdtHx_TtQCOIFQEz3GafvU0uLNsKszWQEe72GDsa6hQEdYN8jUSkWlzdC1JBFpXfRCQaFVLSRtvtr_rWJ_HT463PlxdFpAVd6PdxvOFsUbqQH8BluTapgrR7BLzW8bUdV4bzBqTCPJmUDVap44V_VIuh8AcwQcYWCMPYu_KkTasBm0jogrA";
    axios.post(serverConfig.api_base_url + "get_user_token",{jwt:jwt})
    .then(response=>{
      console.log('get_user_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      }/*  else {
        sessionStorage.setItem('token','abcdefghijklmnop');
        setToken('abcdefghijklmnop');
      } */
    })
    .catch(err=>console.log("error is",err));
  }

  const getCompletedStatusFn = () => {
    axios.post(serverConfig.api_base_url + "get_completed_status",{token: token})
    .then(response=>{
      console.log("get_completed_status response:", response);
      if(response.data.status === 200) {
        setIsLoading(false);
        let res = JSON.parse(response.data.items);
        console.log(res);
        if(res !== null){
          let items = Object.assign({}, ...res);
          console.log(items);
          console.log(steps);

          let newArr = [];
          steps.map((item, index) => {
            for (let i = 0; i < res.length ; i++) {
              let stepVal = Object.keys(res[i])[0]; // This gets the value of the object, assuming the object has only one property
              if(stepVal == item.stepCase) {
                item.status = Object.values(res[i])[0];

                if(item.subSteps){
                  let sub = item.subSteps;
                  let newSubArr = [];
                  sub.map((items,k) => {
                    for (let j = 0; j < res.length ; j++) {
                      let stepVal = Object.keys(res[j])[0]; // This gets the value of the object, assuming the object has only one property
                      if(stepVal == items.subStepCase) {
                        items.subStatus = Object.values(res[j])[0];
                      }
                    }
                    newSubArr.push(items);
                  });
                  console.log(newSubArr);
                }
              }
            }
            newArr.push(item);
          });
          console.log(newArr);
          setFinalSteps(newArr);

          setStepStatus(items);
        }
      } else if(response.data.status === 401){
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    getToken();

    if (window.performance) {
      if (performance.navigation.type == 1) {//on refresh clear session storage
        sessionStorage.clear();
      }
    }
    
    setstep(1);
    setEntityName('company_info');
  }, []);

  useEffect(() => {
    console.log('token and entity changed');
    console.log('Token', token, sessionStorage.getItem('token'));
    window.scrollTo(0, 0);

    if(token !== null && token !== undefined){
      /* check_user_privilege - access_request_list - redirect to applications page */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:token, privilege:'access_request_list'})
      .then(response=>{
        console.log("access_request_list privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            getCompletedStatusFn();
          } else if(response.data.is_privileged === 1){
            navigate("/applications");
          }
        } else if(response.data.status === 401){
          alert(response.data.message);
        } else if(response.data.status === 301){
          getToken();
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [entityName, token]);

  useEffect(() => {
    console.log('token changed');
    console.log('Token', token, sessionStorage.getItem('token'));
    
    if(token !== null && token !== undefined){
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token: token, privilege: 'reset_data'})
      .then(response=>{
        console.log("get_user_list privilege response:", response);
        if(response.data.status === 200) {
          console.log('success');
          console.log(response.data.is_privileged);
          if(response.data.is_privileged === 0){
            setUserPrivileged(false);
          } else if(response.data.is_privileged === 1){
            setUserPrivileged(true);
          }
        } else if(response.data.status === 401){
          alert(response.data.message);
        } else if(response.data.status === 301){
          getToken();
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token]);

  const handleNav = (stepNum, stepName) => {
    console.log(stepNum, stepName);
    setstep(stepNum);
    setEntityName(stepName);
  }

  const handleSubNav = (stepNum, subStepNum, stepName) => {
    console.log(stepNum, stepName);
    setstep(stepNum);
    setsubstep(subStepNum);
    setEntityName(stepName);
  }

  const resetForm = () => {
    console.log('reset form');
    axios.post(serverConfig.api_base_url + "reset_data",{token: token})
    .then(response=>{
      console.log(response);
      if(response.data.status === 200) {
        console.log('success');
        window.location.reload(false);
      } else if(response.data.status === 401){
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  return(
    <div style={isLoading ? {position: 'relative', overflow: 'hidden', height: '100vh'} : {}}>
      {isLoading && <div className="loading"><img src={require('./../assets/images/spinner.gif')} height="70" alt="Spinner" /></div>}
      <Header isLoggedIn={entityName === 'login' ? false : true}/>
      <main>
        <Container fluid>
          <Row>
            <Col sm={3}>
              <ul className='sidebar'>
                {finalSteps.map((item, index) => {
                  var uploadDocClass;
                  //console.log(stepStatus.document_info);
                  //console.log(Object.values(stepStatus));
                  var subStatus = [];
                  subStatus = [stepStatus.document_info, stepStatus.gross_receipts, stepStatus.form_941, stepStatus.payroll, stepStatus.ppp_loans];
                  //console.log(subStatus);
                  if(subStatus.every((val, i, arr) => val === 'E')) {//if array has values only 'C'
                    uploadDocClass = '';
                  } else if(subStatus.every((val, i, arr) => val === 'C')) {//if array has values only 'C'
                    uploadDocClass = 'complete';
                  } else if(Object.values(subStatus).includes('I') || Object.values(subStatus).includes('E')){
                    uploadDocClass = 'incomplete';
                  }
                  /* if(stepStatus.document_info === 'I' || stepStatus.gross_receipts === 'I' || stepStatus.form_941 === 'I' || stepStatus.payroll === 'I' || stepStatus.ppp_loans === 'I'){
                    uploadDocClass = 'incomplete';
                  } else if(stepStatus.document_info === 'C' && stepStatus.gross_receipts === 'C' && stepStatus.form_941 === 'C' && stepStatus.payroll === 'C' && stepStatus.ppp_loans === 'C') {
                    uploadDocClass = 'complete';
                  } */
                  return (
                  <li key={index} className={(step == index+1 ? 'active '+item.status : item.status) + (item.stepCase === 'document_info' ? ' '+uploadDocClass : '')}>
                    <span className='step-status'></span><span className='step-heading' onClick={() => handleNav(index+1, item.stepCase)}>{item.stepHeading}</span>
                    {item.subSteps && <ul>
                      {item.subSteps.map((subitem, subindex) => (
                        <li key={subindex} className={entityName === subitem.subStepCase ? 'active '+subitem.subStatus : subitem.subStatus}>
                          <div>
                            <span className='step-status'></span>
                            <span className='step-heading' onClick={() => handleSubNav(index+1, subindex+1, subitem.subStepCase)}>{subitem.subStepHeading}</span>
                          </div>
                        </li>
                      ))}
                    </ul>}
                  </li>
                )})}
              </ul>
              {userPrivileged && <div className="btn-reset"><Button onClick={resetForm}>Reset</Button></div>}
            </Col>
            <Col sm={9}>
              {(() => {
                switch (entityName) {
                  case 'company_info':
                    return token === null ? "" : (
                      <CompanyInfo nextStep={nextStep} token={token} entityName="company_info" getToken={getToken} />
                    );
                  case 'covid19_info':
                    return token === null ? "" : (
                      <Covid19Info nextStep={nextStep} prevStep={prevStep} token={token} entityName="covid19_info" getToken={getToken} />
                    );
                  case 'employee_info':
                    return token === null ? "" : (
                      <EmployeeInfo nextStep={nextStep} prevStep={prevStep} token={token} entityName="employee_info" getToken={getToken} />
                    );
                  case 'document_info':
                    return token === null ? "" : (
                      <DocumentInfo nextStep={nextSubStep} prevStep={prevStep} token={token} entityName="document_info" getToken={getToken} />
                    );
                  case 'gross_receipts':
                    return token === null ? "" : (
                      <GrossReceipts nextStep={nextSubStep} prevStep={prevSubStep} token={token} entityName="gross_receipts" getToken={getToken} />
                    );
                  case 'form_941':
                    return token === null ? "" : (
                      <Form941 nextStep={nextSubStep} prevStep={prevSubStep} token={token} entityName="form_941" getToken={getToken} />
                    );
                  case 'payroll':
                    return token === null ? "" : (
                      <Payroll nextStep={nextSubStep} prevStep={prevSubStep} token={token} entityName="payroll" getToken={getToken} />
                    );
                  case 'ppp_loans':
                    return token === null ? "" : (
                      <PPPLoans nextStep={nextStep} prevStep={prevSubStep} token={token} entityName="ppp_loans" getToken={getToken} />
                    );
                  case 'power_of_attorney':
                    return token === null ? "" : (
                      <PowerOfAttorney nextStep={nextStep} prevStep={prevStepSub} token={token} entityName="power_of_attorney" getToken={getToken} />
                    );
                  case 'agreements_disclosures':
                    return token === null ? "" : (
                      <AgreementsDisclosures nextStep={nextStep} prevStep={prevStep} token={token} entityName="agreements_disclosures" getToken={getToken} refreshSidebarCompletedStatus={getCompletedStatusFn} />
                    );
                  case 'thank_you':
                    return token === null ? "" : (
                      <Thankyou prevStep={prevStep} token={token} entityName="thank_you" />
                    );
                  // default case to show nothing
                  default:
                    return (
                      <div></div>
                    );
                }
              })()}
            </Col>
          </Row>
        </Container>
        <ClientChat mToken={sessionStorage.getItem('token')} />
      </main>
    </div>
  )
}

export default Signup;
